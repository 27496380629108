import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { CapitalRaisingProps, StockChangeListProps } from "../interface/type";
import {
  getCapitalRaisingList,
  getInitialFaceValue,
  getPortfolioCurrency,
  getPortfolioDetailInfo,
  getStockChangeList,
} from "../../../api/repository/portfolio/PortfolioRepository";
import {
  getNewDate,
  getYMD,
  transformObjectToBase64,
  trnasfromBase64ToObject,
} from "../../../common/commonUtil";
import { getInvestDictionary } from "../../../api/repository/common/CommonRepository";
import {
  ShareHolderConfirmProps,
  getShareholderList,
} from "../../../common/StockChangeListService";
import { PortfolioInfoProps } from "../../portfolio/info/interface/PortfolioInfo.interface";

const useStockChangeListViewer = () => {
  const navigate = useNavigate();
  const [portfolioInfo, setPortfolioInfo] = useState<
    PortfolioInfoProps | undefined
  >(undefined);
  const [stockChangeListResult, setStockChangeListResult] = useState<
    ShareHolderConfirmProps | undefined
  >(undefined);
  const [allStockChangeList, setAllStockChangeList] = useState<
    StockChangeListProps[] | undefined
  >(undefined);

  const [stockChangeList, setStockChangeList] = useState<
    StockChangeListProps[] | undefined
  >(undefined);
  const [selectedCapitalRaisingRow, updateSelectedCapitalRaisingRow] = useState<
    CapitalRaisingProps | undefined
  >(undefined);
  const [selectedStockChangeRow, updateSelectedStockChangeRow] = useState<
    StockChangeListProps | undefined
  >(undefined);

  const [allCapitalRaisingList, setAllCapitalRaisingList] = useState<
    CapitalRaisingProps[]
  >([]);
  const [capitalRaisingList, setCapitalRaisingList] = useState<
    CapitalRaisingProps[]
  >([]);
  const [totalStockNumber, setTotalStockNumber] = useState<number>(0);
  const { pathParam } = useParams();
  const [allStockChangeSubType, setAllStockChangeSubType] = useState<
    {
      id: number;
      name: string;
    }[]
  >([]);
  const [currency, setCurrency] = useState<string>("");
  const [faceValue, setFaceValue] = useState<number>(0);

  const [timeStamp, setTimeStamp] = useState<string>("");
  const [minDate, setMinDate] = useState<string | undefined>(undefined);
  const [maxDate, setMaxDate] = useState<string | undefined>(undefined);

  const onChangeTimeStamp = (time: string) => {
    setTimeStamp(time);
  };

  const getAllStockChangeList = async () => {
    // 주식변동내역 리스트
    if (!pathParam) return;
    const { portfolioId } = trnasfromBase64ToObject(pathParam);
    const stockChangeListResult = await getStockChangeList(portfolioId);
    const faceValueResult = await getInitialFaceValue(portfolioId);

    if (stockChangeListResult && faceValueResult) {
      setAllStockChangeList(stockChangeListResult);
      setFaceValue(faceValueResult);
      setTimeStamp(getYMD(getNewDate()));
      setMinDate(stockChangeListResult[0].date);
    } else {
      setStockChangeList([]);
    }
  };

  const getAllCapitalRaising = async () => {
    // 자본조달 리스트
    if (!pathParam) return;
    const { portfolioId } = trnasfromBase64ToObject(pathParam);
    const result = await getCapitalRaisingList(portfolioId);
    if (result) {
      setAllCapitalRaisingList(result);
    } else {
      setCapitalRaisingList([]);
    }
  };

  const getDictionary = async () => {
    const dictionary = await getInvestDictionary();
    if (dictionary) {
      const subtypeArr: any[] = [];
      dictionary.stockChangeType.forEach((item) => {
        if (item.subType) {
          item.subType.forEach((subtype) => {
            subtypeArr.push(subtype);
          });
        }
      });
      setAllStockChangeSubType(subtypeArr);
    }
  };

  const getCurrency = async () => {
    if (!pathParam) return;
    const { portfolioId } = trnasfromBase64ToObject(pathParam);
    const result = await getPortfolioCurrency(portfolioId);

    if (result !== undefined) {
      setCurrency(result);
    }
  };

  const getPortfolioInfo = async () => {
    try {
      if (!pathParam) return;
      const { portfolioId } = trnasfromBase64ToObject(pathParam);
      const result = await getPortfolioDetailInfo(portfolioId);
      if (result) {
        setPortfolioInfo({ ...result });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const filteringStockChangeList = () => {
    if (!allStockChangeList || !timeStamp) return;

    const timeStampTime = getNewDate(timeStamp).getTime();
    const filteredStockChangeList = allStockChangeList.filter((item) => {
      const itemDate = new Date(getNewDate(item.date).setHours(0, 0, 0, 0));
      const targetDate = new Date(new Date(timeStampTime).setHours(0, 0, 0, 0));

      return itemDate.getTime() <= targetDate.getTime();
    });
    const filteredCapitalRaising = allCapitalRaisingList.filter((item) => {
      const itemDate = new Date(getNewDate(item.date).setHours(0, 0, 0, 0));
      const targetDate = new Date(new Date(timeStampTime).setHours(0, 0, 0, 0));

      return itemDate.getTime() <= targetDate.getTime();
    });
    const stockholderListResult = getShareholderList(
      filteredStockChangeList,
      faceValue
    );

    setCapitalRaisingList(filteredCapitalRaising);
    setStockChangeList(filteredStockChangeList);
    setStockChangeListResult({ ...stockholderListResult });
    setTotalStockNumber(stockholderListResult.totalStockNumber);
  };

  useEffect(() => {
    filteringStockChangeList();
  }, [allStockChangeList, timeStamp]);

  useEffect(() => {
    getPortfolioInfo();
    getAllStockChangeList();
    getAllCapitalRaising();
    getDictionary();
    getCurrency();
  }, []);

  return [
    portfolioInfo,
    stockChangeList,
    stockChangeListResult,
    capitalRaisingList,
    allStockChangeSubType,
    currency,
    selectedStockChangeRow,
    selectedCapitalRaisingRow,
    totalStockNumber,
    timeStamp,
    minDate,
    maxDate,
    onChangeTimeStamp,
    updateSelectedStockChangeRow,
    updateSelectedCapitalRaisingRow,
  ] as const;
};

export default useStockChangeListViewer;
