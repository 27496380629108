import * as ExcelJS from "exceljs";
import saveAs from "file-saver";
import { StockChangeListProps } from "../router/portfolio-viewer-stock-change-history/interface/type";
import { getNewDate, getYMD, getYMDKo } from "../common/commonUtil";
import {
  getPortfolioDetailInfo,
  getStockChangeList,
} from "../api/repository/portfolio/PortfolioRepository";

export interface StockHolderExcelProps {
  date?: string;
  stockChangeType?: string;
  round?: string;
  buyerName?: string;
  buyerUniqueNumber?: string;
  buyerCountry?: string;
  buyerRelationship?: string;
  stockType?: string;
  stockNumber?: string | number;
  stockPrice?: string | number;
  amount?: string | number;
  sellerName?: string;
  sellerUniqueNumber?: string;
  note?: string;
}

const getFmtByCurrency = (currency: string) => {
  switch (currency) {
    case "KRW":
      return `#,##0 "원"`;
    case "IDR":
    case "JPY":
    case "THB":
      return `"${currency}" #,##0`;
    default:
      return `"${currency}" #,##0.00`;
  }
};

const getEmptyCell = (): StockHolderExcelProps => {
  return {
    date: "",
    stockChangeType: "",
    round: "",
    buyerName: "",
    buyerUniqueNumber: "",
    buyerCountry: "",
    buyerRelationship: "",
    stockType: "",
    stockNumber: "",
    stockPrice: "",
    amount: "",
    sellerName: "",
    sellerUniqueNumber: "",
    note: "",
  };
};
const getNoteData = (data: StockChangeListProps) => {
  switch (data.stockChangeType?.id) {
    case 1:
      return "";
    case 2:
      return `${data.stockChangeSubType === 1 ? "제3자발행" : "주주배정"}${
        data.note ? `\n${data.note}` : ""
      }`;
    case 3:
      return `${data.stockChangeSubType === 3 ? "제3자거래" : "옵션행사"}${
        data.note ? `\n${data.note}` : ""
      }`;
    case 4:
      return `${data.note ? `\n${data.note}` : ""}`;
    case 5:
    case 7:
      return `${data.pfStockBatchInfo?.numerator}주당 ${
        data.pfStockBatchInfo?.denominator
      }주 배정${data.note ? `\n${data.note}` : ""}`;
    case 6:
    case 8:
      return `${data.pfStockBatchInfo?.numerator}주를 ${
        data.pfStockBatchInfo?.denominator
      }주로 병합${data.note ? `\n${data.note}` : ""}`;
    case 10:
      return `${data.note ? `\n${data.note}` : ""}`;
    case 11:
      return `${data.note ? `\n${data.note}` : ""}`;
    case 12:
      return `${data.pfConvertTransaction?.stockType.name}(${
        data.pfConvertTransaction?.stockNumber
      })${data.note ? `\n${data.note}` : ""}`;
    case 13:
      return `${data.note ? `\n${data.note}` : ""}`;
    case 14:
      return `액면가 : ${data.faceValue}${
        data.note ? `\n${data.note}` : "" || ""
      }`;
    default:
      return "";
  }
};

export const excelDownloadStockChangeList = async (
  portfolioId: number,
  date?: string,
  currency?: string
) => {
  const portfolioInfo = await getPortfolioDetailInfo(portfolioId);
  const stockChangeList = await getStockChangeList(portfolioId);
  const filterDate = date ? getNewDate(date) : getNewDate();

  if (!stockChangeList || stockChangeList.length === 0) return;

  const filteredStockChangeList = stockChangeList.filter((item) => {
    const rowDate = new Date(getNewDate(item.date).setHours(0, 0, 0, 0));
    const targetDate = new Date(filterDate.setHours(0, 0, 0, 0));

    return rowDate.getTime() <= targetDate.getTime();
  });

  currency = currency || "KRW";
  const companyName = portfolioInfo?.startupInfo.companyName || "회 사 명";
  const ceoName = portfolioInfo?.startupInfo.ceoName || "대 표 명";
  let totalStockNumber = 0;

  const transformData: StockHolderExcelProps[] = [];

  filteredStockChangeList.forEach((item, index) => {
    if (
      item.stockChangeType &&
      [1, 2, 5, 7, 10, 11].includes(item.stockChangeType?.id)
    ) {
      totalStockNumber += item.stockNumber || 0;
    } else if (
      item.stockChangeType &&
      [12].includes(item.stockChangeType?.id)
    ) {
      totalStockNumber +=
        (item.stockNumber || 0) - (item.pfConvertTransaction?.stockNumber || 0);
    } else if (
      item.stockChangeType &&
      [4, 6, 8, 13].includes(item.stockChangeType?.id)
    ) {
      totalStockNumber -= item.stockNumber || 0;
    }

    item.stockChangeType;
    transformData.push({
      date: getYMD(item.date),
      stockChangeType: item.stockChangeType?.name,
      round: item.round?.name || "",
      buyerName: item.buyer?.name,
      buyerUniqueNumber: item.buyer?.stakeHolder.uniqueNumber,
      buyerCountry: item.buyer?.stakeHolder.country
        ? item.buyer.stakeHolder.country.name
        : "",
      buyerRelationship: item.buyer?.relationship
        ? item.buyer?.relationship.name
        : "",
      stockType: item.stockType?.name,
      stockNumber: item.stockNumber || 0,
      stockPrice: item.stockPrice || 0,
      amount: (item.stockNumber || 0) * (item.stockPrice || 0),
      sellerName: item.seller?.name || "",
      sellerUniqueNumber: item.seller?.stakeHolder.uniqueNumber || "",
      note: getNoteData(item),
    });
  });

  if (transformData.length < 21) {
    for (let i = transformData.length; i < 21; i += 1) {
      transformData.push({ ...getEmptyCell() });
    }
  }

  const defaultAlignment: Partial<ExcelJS.Alignment> = {
    vertical: "middle",
    horizontal: "center",
    shrinkToFit: true,
    wrapText: true,
  };

  const defaultBorder: Partial<ExcelJS.Borders> = {
    top: { color: { argb: "ff000000" }, style: "thin" },
    bottom: { color: { argb: "ff000000" }, style: "thin" },
    left: { color: { argb: "ff000000" }, style: "thin" },
    right: { color: { argb: "ff000000" }, style: "thin" },
  };

  const defaultFont: Partial<ExcelJS.Font> = {
    size: 10,
    color: { theme: 1 },
    name: "Malgun Gothic",
  };

  const dataStyle: Partial<ExcelJS.Style> = {
    font: defaultFont,
    alignment: defaultAlignment,
    border: defaultBorder,
    fill: {
      type: "pattern",
      pattern: "none",
    },
  };

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("주식변동이력", {
    pageSetup: {
      horizontalDpi: 1,
      verticalDpi: 100,
      fitToPage: true,
    },
  }); // sheet 이름이 My Sheet
  // sheet 데이터 설정

  worksheet.views = [{ showGridLines: false }];

  worksheet.columns = [
    { width: 15, key: "date" },
    { width: 20, key: "stockChangeType" },
    { width: 10, key: "round" },
    { width: 30, key: "buyerName" },
    { width: 28, key: "buyerUniqueNumber" },
    { width: 30, key: "buyerRelationship" },
    { width: 20, key: "buyerCountry" },
    { width: 20, key: "stockType" },
    { width: 20, key: "stockNumber" },
    { width: 20, key: "stockPrice" },
    { width: 30, key: "amount" },
    { width: 30, key: "sellerName" },
    { width: 28, key: "sellerUniqueNumber" },
    { width: 30, key: "note" },
  ];

  worksheet.getCell("A1").value = "일자";
  worksheet.getCell("B1").value = "변동유형";
  worksheet.getCell("C1").value = "라운드";
  worksheet.getCell("D1").value = "주주명";
  worksheet.getCell("E1").value = "주민/사업자 등록번호";
  worksheet.getCell("F1").value = "관계";
  worksheet.getCell("G1").value = "국적";
  worksheet.getCell("H1").value = "주식종류";
  worksheet.getCell("I1").value = "변동 주식수";
  worksheet.getCell("J1").value = "주당 거래 금액";
  worksheet.getCell("K1").value = "총 거래 금액";
  worksheet.getCell("L1").value = "판매자";
  worksheet.getCell("M1").value = "판매자(주민/사업자 등록번호)";
  worksheet.getCell("N1").value = "비고";

  transformData.forEach((item, index) => {
    worksheet.insertRow(index + 2, { ...item });
  });

  worksheet.eachRow((row, rowNum) => {
    row.eachCell((cell, colNumber) => {
      if (rowNum === 1) {
        cell.style.fill = {
          bgColor: { argb: "FFD8D8D8" },
          fgColor: { argb: "FFD8D8D8" },
          pattern: "solid",
          type: "pattern",
        };
        cell.style.font = { ...defaultFont, bold: true };
        cell.style.alignment = { ...defaultAlignment };
        cell.style.border = { ...defaultBorder };
      }

      if (rowNum > 1) cell.style = dataStyle;

      if (rowNum > 1 && colNumber === 9) {
        cell.style = {
          ...cell.style,
          numFmt: `#,##0 "주"`,
          alignment: { ...cell.style.alignment, horizontal: "right" },
        };
      }

      if (rowNum > 1 && [10, 11].includes(colNumber)) {
        cell.style = {
          ...cell.style,
          numFmt: getFmtByCurrency(currency || "KRW"),
          alignment: { ...cell.style.alignment, horizontal: "right" },
        };
      }
    });
  });

  const lastIndex = transformData.length + 2;

  worksheet.mergeCells(`A${lastIndex}:H${lastIndex}`);
  worksheet.mergeCells(`J${lastIndex}:N${lastIndex}`);

  for (let i = 1; i <= 14; i += 1) {
    const cell = worksheet.getCell(lastIndex, i);
    cell.style.font = { ...defaultFont, bold: true };
    cell.style.alignment = { ...defaultAlignment };
    cell.style.border = { ...defaultBorder };

    if (i === 1) {
      cell.value = "합계";
    }
    if (i === 9) {
      cell.value = totalStockNumber;
      cell.numFmt = "#,##0주";
      cell.alignment.horizontal = "right";
    }

    cell.style.fill = {
      bgColor: { argb: "FFD8D8D8" },
      fgColor: { argb: "FFD8D8D8" },
      pattern: "solid",
      type: "pattern",
    };
  }

  worksheet.mergeCells(`A${lastIndex + 4}:H${lastIndex + 4}`);
  worksheet.getCell(`A${lastIndex + 4}`).value =
    "위 자본금 변동내역은 최종 등기가 완료되어 법적 효력이 있는 내용이며, 사실과 다름이 없음을 확인합니다.";
  worksheet.getCell(`A${lastIndex + 4}`).style = {
    alignment: { ...defaultAlignment, horizontal: "left" },
    font: { ...defaultFont, size: 10, bold: true },
  };

  worksheet.getCell(`G${lastIndex + 7}`).value = getYMDKo(filterDate);
  worksheet.getCell(`G${lastIndex + 7}`).style = {
    alignment: defaultAlignment,
    font: { ...defaultFont, size: 10, bold: true },
  };

  worksheet.mergeCells(`J${lastIndex + 8}:N${lastIndex + 8}`);
  worksheet.getCell(`J${lastIndex + 8}`).value = companyName;
  worksheet.getCell(`J${lastIndex + 8}`).style = {
    alignment: defaultAlignment,
    font: { ...defaultFont, size: 10, bold: true },
  };
  worksheet.mergeCells(`J${lastIndex + 9}:N${lastIndex + 9}`);
  worksheet.getCell(
    `J${lastIndex + 9}`
  ).value = `대표이사       ${ceoName}  (인)`;
  worksheet.getCell(`J${lastIndex + 9}`).style = {
    alignment: defaultAlignment,
    font: { ...defaultFont, size: 10, bold: true },
  };

  // 다운로드
  const mimeType = {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  };
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], mimeType);

  saveAs(blob, `주식변동내역_${companyName}_${getYMD(filterDate)}.xlsx`);
};
